import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {IHourlyWageCalculation} from '../../../../shared/interfaces/hourly-wage.interfaces';
import {FleetService} from '../../../../shared/services/fleet.service';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {HelperService} from '../../../../shared/services/helper.service';

@Component({
  selector: 'app-hourly-wage-calculation',
  templateUrl: './hourly-wage-calculation.component.html'
})
export class HourlyWageCalculationComponent implements OnInit, OnDestroy {
  public hourlyWagePartsSubscription: Subscription;
  public hourlyWagePartsCalculation: IHourlyWageCalculation[];

  constructor(public calculationService: CalculationService,
              public helperService: HelperService,
              public fleetService: FleetService) {
  }

  ngOnInit() {
    this.hourlyWagePartsSubscription = this.calculationService.hourlyWageParts.subscribe(
      (data) => {
        if (data) {
          this.hourlyWagePartsCalculation = data
        } else {
          this.hourlyWagePartsCalculation = [];
        }
      });
  }

  ngOnDestroy() {
    this.hourlyWagePartsSubscription.unsubscribe();
  }

}
