import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { IHourlyWageCalculation } from '../../interfaces/hourly-wage.interfaces';
import { ICalculation } from '../../interfaces/calculation.interface';

@Injectable({
  providedIn: 'root'
})
export class CalculationRestService {

  constructor(private http: HttpClient) {
  }

  public getWagesByFleet(fleetId: number) {
    return this.http.get<IHourlyWageCalculation[]>(
      `${environment.internal_apiUrl}/hourly_wages/byFleet/${fleetId}`
    );
  }

  public getHourlyWageCalculationById(id: number): Observable<IHourlyWageCalculation> {
    return this.http.get<IHourlyWageCalculation>(
      `${environment.internal_apiUrl}/hourly_wages/${id}`
    );
  }

  public updateHourlyWageCalculation(hourlyWageCalculation: IHourlyWageCalculation) {
    return this.http.put<IHourlyWageCalculation>(
      `${environment.internal_apiUrl}/hourly_wages/${hourlyWageCalculation.id}`,
      hourlyWageCalculation
    );
  }

  public getCalculationsByFleetAndType(fleetId: number, type: string) {
    return this.http.get<ICalculation[]>(
      `${environment.internal_apiUrl}/calculation/byFleet/${fleetId}/${type}`
    );
  }


  public getCalculationById(id: number): Observable<ICalculation> {
    return this.http.get<ICalculation>(
      `${environment.internal_apiUrl}/calculation/${id}`
    );
  }

  public updateCalculation(calculation: ICalculation) {
    return this.http.put<ICalculation>(
      `${environment.internal_apiUrl}/calculation/${calculation.id}`,
      calculation
    );
  }

  public createCalculation(calculation: ICalculation) {
    return this.http.post<ICalculation>(
      `${environment.internal_apiUrl}/calculation`,
      calculation
    );
  }

  public deleteCalculation(id: number) {
    return this.http.delete(
      `${environment.internal_apiUrl}/calculation/${id}`
    );
  }

  public findCalculation(fleetId: number, type: string, search: any) {
    return this.http.get<any>(
      `${environment.internal_apiUrl}/calculation/${fleetId}/${type}`, { params: search }
    );
  }

  public sortCalculation(fleetId: number, sortList: any[]) {
    return this.http.put<any[]>(
      `${environment.internal_apiUrl}/calculation/sortByFleet/${fleetId}`,
      sortList
    );
  }
}
