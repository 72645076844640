import {Component, OnDestroy, OnInit} from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Subscription} from 'rxjs';
import {CalculationService} from '../../../../shared/services/calculation.service';
import {FleetService} from '../../../../shared/services/fleet.service';
import {HelperService} from '../../../../shared/services/helper.service';
import {ICalculation} from '../../../../shared/interfaces/calculation.interface';
import {CalculationRestService} from '../../../../shared/services/rest-services/calculation-rest.service';

@Component({
  selector: 'app-aftermarket-calculation',
  templateUrl: './aftermarket-calculation.component.html'
})
export class AftermarketCalculationComponent implements OnInit, OnDestroy {
  public aftermarketCalculations: ICalculation[] = [];
  public aftermarketCalculationSubscription: Subscription;
  public closeResult: string;
  public itemsMoved = false;


  constructor(private modalService: NgbModal,
              public fleetService: FleetService,
              public helperService: HelperService,
              private calculationRestService: CalculationRestService,
              public calculationService: CalculationService) {
  }

  ngOnInit() {
    this.aftermarketCalculationSubscription = this.calculationService.calculations.subscribe(
      (data) => {
        if (data) {
          this.aftermarketCalculations = data;
          this.aftermarketCalculations.sort((a, b) => {
            return a.sortNo - b.sortNo;
          });
        } else {
          this.aftermarketCalculations = [];
        }
      });
  }

  ngOnDestroy() {
    this.aftermarketCalculationSubscription.unsubscribe();
  }

  public drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.aftermarketCalculations, event.previousIndex, event.currentIndex);
    for (let i = 0; i < this.aftermarketCalculations.length; i++) {
      this.aftermarketCalculations[i].sortNo = i + 2;
    }
    this.itemsMoved = true;
  }

  public deleteCalculation(id: number) {
    this.calculationRestService.deleteCalculation(id).subscribe(data => {
      this.calculationService.getCalculationsByFleetAndType(this.fleetService.fleetId.value, 'AFTER_MARKET');
      this.calculationService.resetCalculationIds();
    });
  }

  public saveSorting() {
    const mappedCalculations = this.aftermarketCalculations.map(calc => {
      return {id: calc.id, sortNo: calc.sortNo};
    });
    this.calculationRestService.sortCalculation(this.fleetService.fleetId.value, mappedCalculations).subscribe();
    this.itemsMoved = false;
  }


  public open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, () => {
      this.closeResult = `Dismissed`;
    });
  }
}
