import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {IMCUpdate} from '../../interfaces/material-code';
import {IFleet} from "../../interfaces/fleet.interfaces";

@Injectable({
  providedIn: 'root'
})
export class MaterialCodesRestService {

  constructor(private http: HttpClient) {
  }

  public getMaterialCodesByFleet(id: number): Observable<any> {
    return this.http.get<any>(
      `${environment.internal_apiUrl}/materialcode/byFleet/${id}`
    );
  }

  public getMaterialCodeForIdByFleet(mc: string, fleetId: number): Observable<any> {
    return this.http.get<any>(
      `${environment.internal_apiUrl}/materialcode/${mc}/${fleetId}`
    );
  }


  public createOrUpdateMaterialCodes(mc: IMCUpdate): Observable<IMCUpdate> {
    return this.http.post<IMCUpdate>(
      `${environment.internal_apiUrl}/materialcode`, mc
    );
  }

  public deleteMaterialCode(id: string, fleetId: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.internal_apiUrl}/materialcode/${id}/${fleetId}`
    );
  }

  public deleteGenArtInMaterialCode(id: string, fleetId: number): Observable<any> {
    return this.http.delete<any>(
      `${environment.internal_apiUrl}/materialcode/byGenart/${id}/${fleetId}`
    );
  }


}
