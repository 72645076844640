<div class="info-text center">
  <i class="n4icon-light-outline mr-2"></i>{{'AFTERMARKET.INFO_TEXT_PRIO' | translate}}
</div>

<ng-container *ngIf="aftermarketCalculations.length >0">
  <div cdkDropList class="custom-list drag-drop" (cdkDropListDropped)="drop($event)">
    <div class="list-item list-header">
      <div class="row">
        <div class="col-3">
          {{'GENERAL.DESCRIPTION' | translate}}
        </div>
        <div class="col-3">
          {{'GENERAL.FLEET' | translate}}
        </div>
        <div class="col-3">
          {{'GENERAL.GARAGE' | translate}}
        </div>
        <div class="col-3 text-right">
          {{'GENERAL.OPTIONS' | translate}}
        </div>
      </div>
    </div>
    <div *ngFor="let calculation of aftermarketCalculations" cdkDrag class="list-item" id="{{calculation.id}}"
         [ngClass]="{'inactive': !calculation.active, 'affirm': fleetService.highlightedCalculationId.value === calculation.id}">
      <div (click)="calculationService.setCurrentCalculationById(calculation.id)" class="row">
        <div class="col-3 d-flex align-items-center">
          {{calculation.description}}
        </div>
        <div class="col-3 d-flex align-items-center">
          <i
            [ngClass]="{'n4icon-plus-add-outline': calculation.computationMap.Flotte.operation.includes('CHARGE'), 'n4icon-minus-outline': calculation.computationMap.Flotte.operation.includes('DISCOUNT')}"></i>
          {{calculation.computationMap.Flotte.value}}
          <span> {{(calculation.computationMap.Flotte.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol))}}</span>
        </div>
        <div class="col-3 d-flex align-items-center">
          <i
            [ngClass]="{'n4icon-plus-add-outline': calculation.computationMap.Werkstatt.operation.includes('CHARGE'), 'n4icon-minus-outline': calculation.computationMap.Werkstatt.operation.includes('DISCOUNT')}"></i>
          {{calculation.computationMap.Werkstatt.value}}
          <span> {{(calculation.computationMap.Werkstatt.operation.includes('PERCENTAL') ? '%' : (helperService.getCurrencySmbol() | currencySymbol))}}</span>
        </div>
        <div class="col-3 text-right">
          <i class="n4icon-edit-pen-outline m-2"
             (click)="calculationService.setCurrentCalculationById(calculation.id)"
             [routerLink]="['/fleet/' + fleetService.fleetId.value + '/after_market/' + calculation.id + '/edit_aftermarket_calculation']">
          </i>
          <i class="n4icon-trash-outline m-2"
             (click)="calculationService.setCurrentCalculationById(calculation.id); open(content)"></i>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!aftermarketCalculations || aftermarketCalculations.length== 0">
  <div class="element-box min-h-2 w-100 m-0 mt-5">
    <span class="empty-element">{{'GENERAL.NO_RESULTS' | translate}}</span>
  </div>
</ng-container>

<div class="button-group">
  <button *ngIf="itemsMoved" type="submit" class="btn btn-primary float-left"
          (click)="saveSorting()">{{'BTN.SAVE' | translate }}</button>
  <button type="submit" class="btn btn-outline-secondary float-right ml-2"
          (click)="helperService.locationBackfromMenu(true)">
    {{'BTN.BACK' | translate }}</button>
  <button type="submit" class="btn btn-primary float-right"
          [routerLink]="['/fleet/' + fleetService.fleetId.value + '/after_market/new_aftermarket_calculation/']">
    {{'BTN.NEW_CALCULATION' | translate }}</button>
</div>


<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'AFTERMARKET.DELETE_CALCULATION' | translate}}</h4>
    <button type="button" class="close no-outline" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary"
            (click)="modal.close('Save click')">{{'BTN.CANCEL' | translate}}</button>
    <button type="button" class="btn btn-danger"
            (click)="modal.close('Save click'); deleteCalculation(calculationService.calcIdId.value)">{{'BTN.DELETE_CALCULATION' | translate}}</button>
  </div>
</ng-template>
